<template>
  <div class="accordion" :class="[`accordion--${variant}`, { 'accordion--centered': centered }]">
    <slot />
  </div>
</template>

<script setup lang="ts">
export interface AccordionProps {
  modelValue: number | "all" | null;
  centered?: boolean;
  variant?: "plain" | "divided";
}

export interface AccordionItem {
  id: number;
  active: ComputedRef<boolean>;
  centered: boolean;
  loaded: boolean;
}

const props = withDefaults(defineProps<AccordionProps>(), {
  variant: "divided",
});

const emit = defineEmits(["update:modelValue"]);

const accordionItems = ref<Ref<AccordionItem>[]>([]);
const accordionItemId = ref(0);

const registerAccordionItem = (): Ref<AccordionItem> => {
  const id = ++accordionItemId.value;

  const accordionItem = ref({
    id,
    active: computed(() => props.modelValue === id || props.modelValue === "all"),
    centered: props.centered,
    loaded: props.modelValue === id || props.modelValue === "all",
  });

  accordionItems.value.push(accordionItem);

  return accordionItem;
};

const updateActiveAccordionItem = (id: number) => emit("update:modelValue", props.modelValue === id ? null : id);

provide("activeAccordionItem", toRef(props, "modelValue"));
provide("registerAccordionItem", registerAccordionItem);
provide("updateActiveAccordionItem", updateActiveAccordionItem);
</script>

<style lang="postcss">
.accordion {
  &--divided {
    @apply divide-y;

    .accordion-item {
      .accordion-item__header {
        @apply p-8 py-5;
      }

      .accordion-item__content-inner {
        @apply p-0;
      }

      &--active .accordion-item__content {
        @apply border-t p-8 lg:p-10;
      }
    }
  }

  &--centered {
    .accordion-item .accordion-item__header {
      @apply px-0;
    }
  }
}
</style>
