<template>
  <div :class="['accordion-item', { 'accordion-item--active': accordionItem.active }]">
    <div class="accordion-item__title">
      <component :is="containerComponent">
        <button type="button" class="accordion-item__header group" @click="setActiveAccordionItem">
          <slot name="title">
            <span>
              {{ title }}
            </span>
          </slot>
          <Icon icon="chevron-down" class="text-xs text-supplement transition-colors group-hover:text-paragraph" />
        </button>
      </component>
    </div>
    <div v-if="accordionItem.loaded" class="accordion-item__content">
      <div ref="content" class="accordion-item__content-inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AccordionItem } from "./Accordion.vue";

export interface AccordionItemProps {
  title?: string;
}

defineProps<AccordionItemProps>();

const registerAccordionItem: { (): Ref<AccordionItem> } = injectStrict("registerAccordionItem");
const updateActiveAccordionItem: { (id: number): void } = injectStrict("updateActiveAccordionItem");

const accordionItem = registerAccordionItem();
const containerComponent = computed(() => (accordionItem.value.centered ? resolveComponent("LazyContainer") : "span"));

const setActiveAccordionItem = () => {
  accordionItem.value.loaded = true;
  updateActiveAccordionItem(accordionItem.value.id);
};
</script>

<style lang="postcss">
.accordion-item {
  &__header {
    @apply flex w-full cursor-pointer items-center justify-between py-3;

    &:focus {
      @apply outline-none;
    }
  }

  &__content {
    @apply h-0 overflow-hidden;

    &-inner {
      @apply pb-3;
    }
  }

  &:first-child .accordion-item__header {
    @apply pt-0;
  }

  &:last-child {
    .accordion-item__header {
      @apply pb-0;
    }

    .accordion-item__content-inner {
      @apply pb-0 pt-3;
    }
  }

  &--active {
    .accordion-item {
      &__header .fa-chevron-down {
        transform: rotate(-180deg);
      }

      &__content {
        @apply h-full;
      }
    }
  }
}
</style>
